<template>
  <div class="mainboxlogin">
    <div class="loginbox_left"><img src="../../public/loginbg.jpg" /></div>
    <div class="loginbox_right">
      <img src="../../src/assets/uidsadminlogo.svg" />
      <h1>UI大师管理后台</h1>
      <div class="username">
        <el-input
          v-model="email"
          placeholder="输入账号"
        ></el-input>
      </div>
      <div class="username">
        <el-input
          type="password"
          v-model="password"
          placeholder="输入密码"
        ></el-input>
      </div>
      <el-button
        @click="handleLogin"
        type="primary"
        >登录</el-button
      >
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { reactive, toRefs, onMounted } from 'vue'
import { post } from '../utils/request'

export default {
  name: 'Login',

  setup () {
    const router = useRouter()
    const data = reactive({ email: '', password: '' })

    const handleLogin = async () => {
      try {
        const result = await post('/api/adminuser/login', {
          email: data.email,
          password: data.password
        })
        if (result?.errno === 0) {
          localStorage.isLogin = true
          router.push({ name: 'dashboard' })
        } else {
          alert('邮箱或密码有误！')
        }
      } catch (e) {
        alert('数据请求失败，请稍后重试！')
      }
    }

    const { email, password } = toRefs(data)

    // 页面加载时检查本地存储
    onMounted(() => {
      const savedUseremail = localStorage.getItem('email')
      const savedPassword = localStorage.getItem('password')
      if (savedUseremail && savedPassword) {
        email.value = savedUseremail
        password.value = savedPassword
      }
      if (localStorage.getItem('isLogin') === 'true') {
        router.push({ name: 'dashboard' })
      }
    })

    return {
      email,
      password,
      handleLogin
    }
  }
}
</script>

<style lang="scss">
@media (min-width: 620px) {
  .mainboxlogin {
    display: flex;
    height: 100%;
    background: #fff;
  }
  .loginbox_left {
    width: 40%;
    height: 100vh;
    overflow: hidden;
  }
  .loginbox_left > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .loginbox_right {
    width: 40%;
    margin: 100px 0 0 50px;
  }
  .loginbox_right > h1 {
    margin-top: 100px;
  }
  .el-input {
    height: 46px;
    margin-bottom: 30px
  }
  .loginbox_right .el-button {
    height: 46px;
    width: 99%;
    margin-bottom: 30px
  }
}

/* 手机样式 -------------------------------------------------------------------------------*/
@media (max-width: 620px) {
  .mainboxlogin {
    display: flex;
    height: 100%;
    background: #fff;
  }
  .loginbox_left {
    display: none;
  }
  .loginbox_right {
    width: 86%;
    margin: 100px 0 0 30px;
  }
  .loginbox_right > h1 {
    margin-top: 100px;
  }
  .el-input {
    height: 46px;
    margin-bottom: 30px
  }
  .loginbox_right .el-button {
    height: 46px;
    width: 99%;
    margin-bottom: 30px
  }
}
@import "../style/index.scss";
</style>
